import React from 'react';

const Pin = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 300 600"
    className="pin"
    {...props}
  >
    {/* <path
      d="M127.062,25.537c-0.118,3.194-0.189,5.123,4.84,16.632c2.54,5.812,5.114,17.09,5.906,30.408h21.382
        c0.793-13.317,3.367-24.596,5.907-30.408c5.029-11.509,4.958-13.437,4.84-16.632c-0.029-0.789,0-1.629,0-2.589
		    C169.938,13.693,163.085,0,148.5,0c-14.585,0-21.438,13.693-21.438,22.948C127.062,23.907,127.091,24.748,127.062,25.537z"
    />
    <path
      d="M133.866,297h29.269c7.391,0,14.151-4.272,17.21-11.001c9.325-20.516,16.738-52.966,16.738-77.077
		    c0-29.223-7.454-53.278-22.788-73.539c-3.37-4.453-6.068-9.366-8.212-14.54h-35.163c-2.145,5.174-4.842,10.087-8.212,14.54
		    c-15.334,20.261-22.788,44.316-22.788,73.539c0,24.111,7.413,56.561,16.738,77.077C119.714,292.728,126.474,297,133.866,297z"
    />
    <path d="M159.053,86.317h-21.106c-0.281,6.843-1.108,13.896-2.686,20.786h26.477C160.16,100.213,159.334,93.16,159.053,86.317z" /> */}

    <path
      d="M125.527,576.943h51.857c10.557,0,23.333-7.458,27.951-16.954c15.291-31.432,44.714-101.984,40.688-172.517
			c-5.403-94.717-75.353-117.313-64.585-199.111c9.753-74.147,38.078-165.049-13.607-184.662
			c-8.521-3.232-15.443-3.634-16.381-3.691C150.523,0.057,143.6,0.468,135.08,3.7c-51.695,19.604-23.361,110.504-13.598,184.652
			c10.758,81.807-59.192,104.403-64.595,199.11c-4.026,70.533,25.398,141.085,40.688,172.517
			C102.194,569.485,114.96,576.943,125.527,576.943z"
    />
  </svg>
);

export default Pin;
